import * as React from 'react'

import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const httpsExample = '../images/browser-https.webp'

const IndexPage = () => {
  return (
    <Layout title="Best Practices">
      <h1>Best Practices</h1>
      <p className="usa-intro">
        It's all in the name.
      </p>
      <p>In the context of optimization,
        this category refers to well known preventative measures for expected problems.
        Failure to follow these recommended patterns often lead to common defects, security breaches, and other expected bad outcomes.
      </p>
      <h2 id="whatarethey">What are they?</h2>
      <p>
        Take an example in cooking. One best practice in cooking pasta is to always leave a wooden spoon over a pot of boiling water. Without the wooden spoon,
        your pasta will still cook, but the spoon will do a good job of preventing the water from boiling over the sides.
      </p>
      <p>Taking care to use risk mitigation strategies in web development can be much more rewarding. Testing your
        web application in multiple browsers is one smart mitigation strategy. Ninety percent of users may use Google Chrome or Mozilla Firefox, but if you do not test your
        website in Microsoft Edge, ten percent of your users could be unable to login to your website, and you would never know until they
        call your office. Best practices today avoid future problems tomorrow.
      </p>
      <h3>A few best practices in web development</h3>
      <ul className='usa-list'>
        <li>Securing your website with Hypertext Transfer Protocol Secure (HTTPS):
          <ul className='usa-list'>
            <li><strong>Problem</strong>: Bad actors can intercept HTTP requests and read sensitive data or even fake responses.</li>
            <li>
              <strong>Solution</strong>: <a target="_blank" rel="noreferrer" className="usa-link" href='https://web.dev/is-on-https/'>Obtain an HTTPS certification and enable HTTPS on all APIs.</a>
              <StaticImage
                alt="Google Chrome browser indicating that a website is using HTTPS"
                placeholder="none"
                src={httpsExample}
              />
            </li>
          </ul>
        </li>
        <li>Keeping user trust when requesting their geolocation:
          <ul className='usa-list'>
            <li>
              <strong>Problem</strong>: Users are mistrustful of or confused by pages that automatically request their location on page load.</li>
            <li>
              <strong>Solution:</strong> <a target="_blank" rel="noreferrer" className="usa-link" href='https://web.dev/geolocation-on-start/'>Always request geolocation permission after
                a user action, not on page load, and have a backup functionality if they do not want to provide it.</a>
            </li>
          </ul>
        </li>
      </ul>
      <h2 id="bestbestpractices">Who decides the "best" best practices?</h2>
      <h3>Then</h3>
      <p>
        There is no definitive guide to best practices, and there doesn't need to be. As the field of software
        development has matured, different individuals, teams, and organizations have encountered
        the same basic problems over and over. Before open-source development, the "correct" solution
        would often come in the form of developer's guides from large enterprise companies.
      </p>
      <h3>Now</h3>
      <p>
        In the modern day,
        cornerstone projects such as Kubernetes and React.js are open-sourced, and anyone can throw an idea
        into the ring. As community projects, developers can form a consensus through discussion and even edit
        the documentation themselves to include newly found "best practices". These lists eventually get manually
        collected and added into the automated code quality checks that most development teams will deploy in their
        delivery process. This ensures that those not "in the know" can still follow best practices.
      </p>
    </Layout>

  )
}

export default IndexPage
